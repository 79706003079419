import { useState, useEffect,useRef } from "react";
import { getDoc, doc,updateDoc,onSnapshot } from "firebase/firestore";
import { db } from "./firebase.js";
import { ThreeDots } from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,List, ListItem, ListItemText,ListItemIcon } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';

const StoreDBList = ({setUserName,userName}) => {
    const [loading, setLoading] = useState(0);
    const [fields, setFields] = useState([]);
    const [dataDb, setDataDb] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const handleChangePageSize = (params) => {
        setPageSize(params.pageSize);
      };
    const [isMobile, setIsMobile] = useState(false);
    const [buttonNumbers, setButtonNumbers] = useState([]);
    const [countdown, setCountdown] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const [kakeru, setKakeru] = useState(0);
    const [kakerareru, setKakerareru] = useState(0);
    const [tokuten, setTokuten] = useState(0);
    const [lastTokuten, setLastTokuten] = useState(0);
    //let lastTokuten = 0;
    const scrollRef = useRef(null); // 追加: refを作成
    const dummy = useRef(null); // 追加: refを作成

    const searchParams = new URLSearchParams(window.location.search);
    const modeParam = searchParams.get('mode');
    let docRef = doc(db, "ranking", "top10new");
    if (modeParam === "child") {
        docRef = doc(db, "ranking", "childnew");
    }    
    let docRefc = doc(db, "ranking", "top10new");

    const [fullCnt, setFullCnt] = useState(9);
    
    const ListWithBorder = ({ items }) => {
        return (
            <ul>
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
        );
      };

    //初回時
    useEffect(() => {
        console.log("Initial");
        //console.log("log1");
        //setLoading(1);


        console.log(modeParam)

        //firestore読み込み
        getDoc(docRef).then((docSnapshot) => {
            //console.log("log2");
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const fieldEntries = Object.entries(data);
                // フィールド名でソート（点数の高い順に並ぶように逆順に）
                //fieldEntries.sort((a, b) => a[0].localeCompare(b[0]));
                fieldEntries.sort((a, b) => b[1].localeCompare(a[1]));
                // 上位10件を取得
                const top10 = fieldEntries.slice(0, 30);
                setFields(top10);
                console.log(top10);
                setLoading(1);
            } else {
                setLoading(2);
                //console.log("Document not found!");
            }
        });

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const fieldEntries = Object.entries(data);
                // フィールド名でソート（点数の高い順に並ぶように逆順に）
                //fieldEntries.sort((a, b) => a[0].localeCompare(b[0]));
                fieldEntries.sort((a, b) => b[1].localeCompare(a[1]));
                // 上位10件を取得
                const top10 = fieldEntries.slice(0, 30);
                setFields(top10);
                console.log(top10);
                setLoading(1);
            } else {
                setLoading(2);
                //console.log("Document not found!");
            }
        });



        // ウィンドウの幅が600px以下の場合はスマートフォンと判定
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        handleResize(); // ページロード時に一度実行
        window.addEventListener('resize', handleResize); // リサイズ時に実行
        return () => {
            unsubscribe();
            window.removeEventListener('resize', handleResize); // イベントリスナーの解除
        };
    }, []);


    const [contained, setContained] = useState(true);

    // 九九の答えを生成
    const generateMultiplicationTable = () => {
        const table = [];
        for (let i = 2; i <= 9; i++) {
            for (let j = 1; j <= 9; j++) {
                table.push(i * j);
            }
        }
        return table;
    };

    const [buttonStyles, setButtonStyles] = useState([
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        { variant: 'contained' },
        // Add more buttons here with their initial styles
    ]);
    const handleButtonClick = (index) => {
        setButtonStyles((prevStyles) => {
            const newStyles = [...prevStyles];
            newStyles[index] = { variant: prevStyles[index].variant === 'contained' ? 'outlined' : 'contained' };
            if (prevStyles[index].variant==='contained') {
                setFullCnt(fullCnt-1);
                console.log(fullCnt-1);
            }else{
                setFullCnt(fullCnt+1);
                console.log(fullCnt+1);
            }
            return newStyles;
        });
    };

    const handleRandomSelection = () => {
        const containedButtons = buttonStyles.filter((style) => style.variant === 'contained');
        if (containedButtons.length > 0) {
            const randomIndex = Math.floor(Math.random() * containedButtons.length);
            const selectedButtonIndex = buttonStyles.findIndex((style) => style === containedButtons[randomIndex]);
            console.log(selectedButtonIndex+1);
        }
    };
    //const [name, setName] = useState(""); // 初期値は空文字列

    const handleScrollToBottom = () => {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleInputChange = (event) => {
      setUserName(event.target.value); // 入力された値をstateに設定
      window.localStorage.setItem('kl-multiplication-user', event.target.value);
      console.log(event.target.value);
    };

    

    const hantei = (number) => {
        //console.log(kakeru*kakerareru);
        if (number === kakeru*kakerareru) {
            console.log("正解")
            //setCountdown(countdown);
            handleStartCountdown(countdown)
            const correctAudio = new Audio("maru.mp3");
            correctAudio.play();
            setTokuten(tokuten+(number));
        }else{
            console.log("不正解")
            setCountdown(countdown-20);
            const correctAudio = new Audio("batsu.mp3");
            correctAudio.play();
            setTokuten(tokuten-(number));
        }
    }

    useEffect(() => {
        let timer;
        if (isRunning && countdown > 0) {
            timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        if (isRunning && countdown <= 0) {
            setLastTokuten(tokuten);
            if (tokuten > 0) {
                const addFieldToTop10 = async () => {                  
{                        try {
                    // 既存のフィールドを含む現在のドキュメントデータを取得
                    const docSnapshot = await getDoc(docRef);
                    const data = docSnapshot.data(); // Firestoreからのデータを取得
                    if (docSnapshot.exists()) {
                        // 既存のフィールドと新しいフィールドをマージして更新
                        let zeronumber ="---"
                        let userName_="ゲストさん"
                        let maxnum = 0;
                        if (fullCnt===9) {
                            zeronumber = String(tokuten).padStart(6, '0')+"★";
                            userName_ = userName+"★"
                            maxnum = data[userName+"★"]; // 名前でプロパティにアクセス
                        }else{
                            zeronumber = String(tokuten).padStart(6, '0');
                            userName_ = userName
                            maxnum = data[userName]; // 名前でプロパティにアクセス
                        }
                        maxnum = maxnum ? parseInt(maxnum.replace("★", "")) : 0;
                        console.log(maxnum);
                        if ( maxnum < tokuten) {
                            const newData = {
                                ...docSnapshot.data(),
                                [userName_]: zeronumber,
                            };
                            await updateDoc(docRef, newData);
    
                            if (modeParam === "child") {
                                await updateDoc(docRefc, newData);
                            }      
                            console.log("更新");
                        }

                        console.log("ドキュメントのフィールドを更新しました");
                        //window.location.reload();
                    } else {
                        console.log("指定したドキュメントが存在しません");
                    }
                    } catch (error) {
                    console.error("ドキュメントの更新に失敗しました", error);
                    }}
                };
                addFieldToTop10();    
            }
            //lastTokuten = tokuten;
            //console.log(lastTokuten)
            handleStopCountdown();
        }
        return () => clearInterval(timer);
    }, [isRunning, countdown]);

    const handleStartCountdown = (count) => {
        setIsRunning(true);
        setCountdown(count);
                //ランダム選択(かけられる側)
                const containedButtons = buttonStyles.filter((style) => style.variant === 'contained');
                let kakerareru = 0;
                if (containedButtons.length > 0) {
                    const randomIndex = Math.floor(Math.random() * containedButtons.length);
                    const selectedButtonIndex = buttonStyles.findIndex((style) => style === containedButtons[randomIndex]);
                    kakerareru = selectedButtonIndex+1;
                    setKakeru(selectedButtonIndex+1);
                }
                //ランダム(かける側)
                const min = 1; // 最小値（1）
                const max = 9; // 最大値（9）
                const kakeru = Math.floor(Math.random() * (max - min + 1)) + min
                setKakerareru(kakeru);        
                // 1つだけランダムにボタンを決定し、それ以外は九九の答えからランダムに選ぶ
                const multiplicationTable = generateMultiplicationTable();
                const randomNumbers = [];
                let randomIndex = Math.floor(Math.random() * 5); // 1つだけランダムにボタンを決定
                while (randomNumbers.length < 5) {
                    if (randomNumbers.length === randomIndex) {
                        randomNumbers.push(kakeru*kakerareru); // ランダムな数字を追加
                    } else {
                        const randomNumber = multiplicationTable[Math.floor(Math.random() * multiplicationTable.length)];
                        if (!randomNumbers.includes(randomNumber)) {
                        randomNumbers.push(randomNumber);
                        }
                    }
                }
                setButtonNumbers(randomNumbers);
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: "smooth" ,block: "start"});
                }
    };
    const handleStopCountdown = () => {
        setTokuten(0);
        setCountdown(60);
        setIsRunning(false);
    };


    const items = ['まずは[なまえ]を変更しよう', '制限時間60秒以内に多くの問題を解け！', '答えがそのまま点数に加算されるよ', '間違えると制限時間20秒マイナス…点数も引かれちゃう', '解く問題は[1の段]～[9の段]のボタンで変更できるよ', '全部の段で解いた時はスコアに★がつくよ', '準備ができたら[開始]ボタンで競い合おう！'];
    return (
    <>
        {loading===2 && (
        <>
            DB読み込みに失敗しました。<br />
        </>
        )}
        {loading===1 && (
        <>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                {/* 左側のdiv */}
                <div style={{ flex: 1 }}>
                    {/* ここに左側のコンテンツを追加 */}
                    <div style={{ height: '100', width: '300px', padding: '20px' }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Rank</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map(([name, rank], index) => (
                                        <TableRow key={index}>
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{name.split('★')[0]}</TableCell>
                                        <TableCell>{rank.split('_')[0]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                {/* 右側のdivたち */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 3 }}>
                    <div>
                        <ListWithBorder items={items} />
                    </div>
                    <div style={{ padding: '10px' }}>
                        {/* 右側の上のコンテンツ */}
                        {buttonStyles.map((style, index) => (
                            <Button key={index} variant={style.variant} onClick={() => handleButtonClick(index)} style={{ margin: '5px' }}>
                            {index + 1}の段
                            </Button>
                        ))}
                    </div>
                    <div style={{ padding: '10px' }} ref={isMobile ? scrollRef : dummy}>
                        {isMobile && (
                            <div style={{ padding: '24px' }}/>
                        )}
                        {/* 右側の中央のコンテンツ */}
                        <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-with-icon-adornment">
                                なまえ
                                </InputLabel>
                                <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                    <AccountCircle />
                                    </InputAdornment>
                                }
                                value={userName} // 入力された値を表示
                                onChange={handleInputChange} // 入力値が変更されたときに呼ばれるハンドラー
                                />
                            </FormControl>
                            <p>残り時間: {countdown}秒　得点：{tokuten}</p>
                            {isRunning ? (
                                <Button variant="outlined" onClick={handleStopCountdown}>
                                停止
                                </Button>
                            ) : (
                                <Button variant="contained" onClick={() => handleStartCountdown(60)}>
                                開始
                                </Button>
                            )}
                        </Box>
                    </div>
                    <div style={{padding: '10px',height:'100px'}}>
                        {/* 右側の下のコンテンツ */}
                        {isRunning ? (
                            <>
                                <Typography variant="h1">{kakeru} × {kakerareru} =</Typography>
                            </>
                        ) : (
                            <>
                                {lastTokuten !== 0 &&(
                                    <>
                                        <Box sx={{ '& > :not(style)': { m: 1 } }}>
                                            <p>最後の得点：{lastTokuten}</p>
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div style={{padding: '10px',height:'50px'}} >
                        {/* 右側の下のコンテンツ */}
                        {isRunning ? (
                            <>
                                <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                                    {buttonNumbers.map((number, index) => (
                                        <Button key={index} variant="contained" onClick={() => hantei(number)}>
                                            {number}
                                        </Button>
                                    ))}
                                    
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </div>
                    <div>
                        <div /> {/* 追加: スクロールさせたい要素にrefを設定xa */}
                    </div>
                </div>
            </div>
        </>
        )}
        {loading===0 && (
            <div className="chuou">
                <ThreeDots height="80" width="80" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true}/>
            </div>
        )}
    </>
    );
};

export default StoreDBList;
