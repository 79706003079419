import TopAppBar from "./_TopAppBar.js";
import StoreDBList from "./_StoreDBList.js";
import { useState,useEffect } from "react";
import { ThreeDots } from 'react-loader-spinner';

const Top = () => {
    const [userName, setUserName] = useState(window.localStorage.getItem('kl-multiplication-user'));
    //ログインチェック
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //window.localStorage.removeItem('kl-multiplication-user');
        setUserName(window.localStorage.getItem('kl-multiplication-user'));
        if (!userName) {
            window.localStorage.setItem('kl-multiplication-user', "ゲストさん");
            setUserName("ゲストさん");
          }
        console.log(userName);
        setLoading(false);
    }, []);
    

    return (
        <>
            {!loading ? (
                <>
                    <TopAppBar setUserName={setUserName}/> {/*ヘッダー*/}
                    <div style={{ marginTop: '64px' }}>
                        <StoreDBList setUserName={setUserName} userName={userName}/>
                    </div>
                </>
            ):(
                <div className="chuou">
                    <ThreeDots height="80" width="80" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true}/>
                </div>
            )}
        </>
    )
}

export default Top;