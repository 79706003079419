//import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// 3. Authを使うので、以下をインポートする
//import { getAuth, GoogleAuthProvider } from "firebase/auth";
// 4. firestoreを使うので、以下をインポートする
import { getFirestore } from "firebase/firestore";
import { getAuth,onAuthStateChanged,signOut } from "firebase/auth";
//import { useNavigate } from "react-router-dom";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// 4. authとfirebaseそれぞれ実行、インスタンス化
const auth = getAuth(app);
//const provider = new GoogleAuthProvider();
const db = getFirestore(app);


const fsGetList = (a,b) => {
    const c = a + b;
    return (
        <>
            {c}
        </>
    )
}

//認証されているかチェック
const fbAuthCheck = () => {
    //const [loading, setLoading] = useState(true);

    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                //ログインあり
                resolve({ _email: currentUser.email, _loading: false });
                //setLoading(false);
            }else{
                //ログインなし
                resolve({ _email: null, _loading: false });
                //setLoading(false);
            }
        }, reject);
    });
}


//ログアウトを行なう。
const fbAuthLogout = async () => {
    await signOut(auth);
}


// exportしてどこからでも使えるようにする
//export { auth, provider, db};
export {auth,db,fsGetList,fbAuthCheck,fbAuthLogout,analytics};


