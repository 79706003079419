import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createRoot } from 'react-dom/client';		//React使用時のおまじない
import './index.css';								//スタイル設定
import reportWebVitals from './reportWebVitals.js';	//ログ解析用に。(reportWebVitals();)とセット
import Top from "./Top.js";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={`/`} element={<Top/>} />
                </Routes>
            </BrowserRouter>
        </>
    );
};    

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();									//ログ解析用に。(import reportWebVitals from './reportWebVitals';)とセット
