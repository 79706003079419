import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // メニューアイコンのインポート
import HomeIcon from '@mui/icons-material/Home'; // ホームアイコンのインポート
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fbAuthLogout } from "./firebase.js";
import { useNavigate } from 'react-router-dom';
 
const TopAppBar = ({ umail , setEditFlg , setDeleteFlg}) => {
        
    //ログアウト処理
    const navigate = useNavigate();
    const Logout = async () => {
        await fbAuthLogout(); // firebase.jsのfbAuthLogout関数を呼び出す
        navigate("/login/");
      };

    const Edit = async () => {
        setEditFlg((prevEditFlg) => (prevEditFlg === false ? true : false));
        setDeleteFlg(false);
    }
    const Delete = async () => {
        setDeleteFlg((prevDeleteFlg) => (prevDeleteFlg === false ? true : false));
        setEditFlg(false);
    }

    return (
        <AppBar position="fixed">
            <Toolbar>
                {/* ハンバーガーアイコンを表示 */}
                <IconButton edge="start" color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                {/* タイトルを表示 */}
                <Typography variant="h6" style={{padding:10}}>九九ゲーム v1.13</Typography>
                {/* ナビゲーションリンクを表示 */}
                <IconButton color="inherit" aria-label="home"><HomeIcon /></IconButton>
                {/*<IconButton color="inherit" aria-label="edit" onClick={Edit}><EditIcon /></IconButton>
                <IconButton color="inherit" aria-label="CameraAlt"><CameraAltIcon /></IconButton>
                <IconButton color="inherit" aria-label="delete" onClick={Delete}><DeleteIcon /></IconButton>*/}
                {!umail ? (
                    <>
                        {/* ログインボタンを表示 */}
                        <IconButton color="inherit" style={{ marginLeft: 'auto' }} aria-label="Login"><LoginIcon /></IconButton>
                    </>
                ):(
                    <>
                        {/* ログインボタンを表示 */}
                        <IconButton color="inherit" style={{ marginLeft: 'auto' }} aria-label="Logout" onClick={Logout}><LogoutIcon /></IconButton> 
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;